import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  constructor(
    private title: Title,
    private translate: TranslateService,
  ) {
    this.title.setTitle('Attorney - Payee');
  }

  ngOnInit(): void {
    this.translate.addLangs(['hu']);
    this.translate.setDefaultLang('hu');
    this.translate.use('hu');
  }
}
